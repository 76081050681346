@import '../../globals/Colors.scss';

.loading-pane {
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
        border: 4px solid #f3f3f3;
        border-top: 4px solid $blue;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}