$bg-light-blue: #ECF2FE;
$bg-grey: #ECECEC;
$bg-light-orange: #FED693;
$bg-orange: #F5AD52;
$bg-light-green: #BBE4D1;
$bg-light-green-2: #76C7BE;
$bg-light-grey-blue: #A7C7E7;

$blue: #2196F3;
$blue-2: #349CFF;
$blue-3: #8A94A6;
$hint: rgba(0, 0, 0, 0.38);
$grey: rgba(0, 0, 0, .6);
$grey-2: rgba(0, 0, 0, .12);
$grey-3: #5B778C;
$light-green: #82BD41;
$green: #4F9764;
$navy: #4E5D78;
$navy-2: #125FAB;
$orange: #F5AD52;
$purple: purple;
$purple-2: #735EA1;
$teal: #03ACC9;
$white: #FFFFFF;
$light-grey: #B7B7B7;