@import '../../../../globals/Colors.scss';

.competency-header {
  background-color: #DAEDFF;
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;

  .competency-icon {
    height: 25px;
    width: 22px;
    filter: brightness(0) saturate(100%) invert(31%) sepia(20%) saturate(560%) hue-rotate(178deg) brightness(87%) contrast(89%);
    margin: 0 8px;
  }
}

.user-response-row {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  height: 50px;
  align-items: center;
}