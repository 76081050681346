@import '../../../../globals/Colors.scss';

.question-container {
  margin-left: 10px;
  width: 50%;
}
.score-container {
  display: flex;
  flex-direction: row;

  .score-bubble {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    margin: 0 5px;
    border: 1px solid #C0C0C0;
    background-color: white;
  }

  .score-bubble.left-bubble {
    margin: 0 5px 0 20px;
  }

  .score-bubble.right-bubble {
    margin: 0 30px 0 5px;
  }

  .score-bubble.one-match {
    border: 1px solid #E89A37;
    background-color: #F5AD52;
  }

  .score-bubble.two-match {
    border: 1px solid #F3C578;
    background-color: #FED693;
  }

  .score-bubble.three-match {
    border: 1px solid #DEC8A4;
    background-color: #DDCEB4;
  }

  .score-bubble.four-match {
    border: 1px solid #A6D9C1;
    background-color: #BBE4D1;
  }

  .score-bubble.five-match {
    border: 1px solid #64B2AA;
    background-color: #76C7BE;
  }
}