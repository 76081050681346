@import '../../globals/Colors.scss';

.checkbox-list {
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 175px;
  font-weight: 400;
  font-size: 12px;
  position: absolute;
  text-align: left;
  color: #023151;

  .filter-by-header {
    font-weight: 500;
    opacity: 0.75;
    margin: 2px 10px 10px 7px;
  }

  .checkbox-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .checkbox-label {
      margin: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .checkbox-box {
        border: 1px solid #C0C0C0;
        border-radius: 8px;
        opacity: 0.5;
        margin-right: 10px;
        height: 20px;
        width: 20px;
      }
    }
  }
}