@import '../../globals/Colors.scss';

.tooltip-view {
    margin: 0px;
    padding: 0px;
    display: flex;
    list-style: none;
    
    .tooltip-cell {
        display: flex;
        align-items: center;
        margin-right: 40px;

        .hex {
            width: 19px;
            height: 19px;
            border-radius: 50%;
        }

        .title-frame {
            display: flex;
            margin-left: 15px;
            flex-direction: column;
            justify-content: center;

            .title, .subtitle {
                color: $navy;
                font-size: 12px;
            }
    
            .subtitle {
                color: $grey;
                font-size: 10px;
            }
        }
    }

    .tooltip-cell:last-child {
        margin: 0px;
    }
}