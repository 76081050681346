@import '../../globals/Colors.scss';

.user-report-view {
  // position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;

  .toolbar {
    position: relative;
    width: 100%;
    display: flex;
    flex: 0 0 60px;
    align-items: center;
    border-bottom: 1px solid $blue-3;

    .back-button, .print-button {
      position: relative;
      z-index: 1;
      font-size: 16px;
      padding: 20px;
      border: none;
      color: $blue;
      cursor: pointer;
      background: none;
      box-sizing: border-box;
    }

    .print-button {
      position: absolute;
      right: 0px;
    }

    .toolbar-header {
      position: absolute;
      left: 0px;
      right: 0px;
      z-index: 0;
      
      .header, .subheader {
        color: $navy;
        font-size: 13px;
        text-align: center;
      }

      .header {
        font-weight: bold;
        font-size: 15px;
      }
    }
  }

  .tabs-list-view {
    margin: 15px 0px;
  }

  .content-frame {
    display: flex;
    padding: 45px;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;

    .categories-list-view {
      width: 90%;
      margin: 0px;
      padding: 0px;
      display: flex;
      list-style: none;
      max-width: 820px;
      flex-wrap: wrap;
    }
  
    .category-cell {
      flex: 0 0 45%;
      height: 57px;
      margin-top: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .name {
        display: flex;
        vertical-align: middle;
        align-items: center;

        .competency-category-name {
          margin-left: 10px;
        }
      }
  
      .name, .scored-category {
        font-size: 14px;
        font-weight: bold;
        color: $navy;
      }

      .name > .competency-icon {
        height: 35px;
        width: 30px;
        filter: brightness(0) saturate(100%) invert(41%) sepia(87%) saturate(722%) hue-rotate(182deg) brightness(95%) contrast(88%);
        margin: 0 12px;
      }
  
      .scored-category {
        font-size: 12px;
        width: 55px;
        height: 38px;
        display: flex;
        border-radius: 19px / 50%;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        
      }
    }
  
    .category-cell:nth-child(odd) {
      margin-right: 10%;
    }
  
    .category-cell.overall {
      width: 45%;
      max-width: 320px;
      flex-basis: 57px;
      // margin: 36px 0px 50px 0px;
      border-bottom: 1px solid $blue-3;
      margin: 0;
  
      .scored-category {
        width: auto;
        border-radius: 25px;
        padding: 0px 5px;
        width: 55px;
        height: 38px;
        display: flex;
        border-radius: 19px / 50%;
      }
    }
  }

  .user-report-tooltip {
    margin: 30px 0 30px 0;
    display: flex;
    justify-content: center;
  }

  .user-report-timepoints {
    margin: 30px 0;
    display: flex;
    justify-content: center;
  }
}

.user-report-view.pdf {
  .content-frame {
    padding: 0px;
  }

  .selection-menus-frame {
    .arrow-icon {
      display: none;
    }
  }

  .user-responses-container {
    padding: 40px;
    box-sizing: border-box;
  }
}

@media print {
  .user-report-view.pdf {
    print-color-adjust: exact;

    .back-button, .print-button {
      display: none;
    }

    .top-section {
      padding: 20px 0px !important;
    }

    .tooltip-cell {
      margin-right: 20px;
    }

    .competency-header, .user-response-row {
      break-inside: avoid;
    }

    .question-container, .score-container {
      display: flex;
      align-items: center;
      font-size: 12px;
    }

    .selection-menus-frame {
      justify-content: center !important;

      .select-menu-view {
        flex-basis: 30% !important;
        margin: 0 1% !important;

        .arrow-icon {
          display: none;
        }
      }
    }
  }
}