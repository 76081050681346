@import '../../../globals/Colors.scss';

.filter-report-stats-view {
    width: 100%;
    display: flex;
    padding: 30px;
    border-radius: 8px;
    background: $white;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;

    .breadcrumb-view {
        display: flex;
        flex-wrap: wrap;
        border-radius: 8px;
        background: $bg-grey;

        .breadcrumb-frame {
            display: flex;
            padding: 14px;
            align-items: center;

            .breadcrumb-icon {
                margin-right: 7px;
            }

            .breadcrumb-hint {
                color: $navy;
                font-size: 14px;
                font-weight: bold;
                margin-right: 5px;
            }

            .breadcrumb-value {
                color: $navy;
                font-size: 14px;
                text-wrap: nowrap;
            }
        }

        .breadcrumb-frame.first {
            margin-left: 0px;
        }
    }

    .stats-frame {
        display: flex;
        margin-top: 24px;

        .stat-frame {
            display: flex;
            margin-left: 37px;

            img {
                align-self: center;
                margin-right: 11px;
            }

            .right-section {
                display: flex;
                flex-direction: column;

                .stat-header {
                    color: $navy;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 140%;
                }
    
                // .stat-value, .orange {
                //     display: flex;
                //     font-size: 28px;
                //     line-height: 100%;
                //     font-weight: bold;
                // }

                .stat-value, .purple {
                    display: flex;
                    font-size: 28px;
                    line-height: 100%;
                    font-weight: bold;
                }

                .pct {
                    font-size: 10px;
                }

                .districts {
                    color: $blue-2;
                }

                .schools {
                    color: $bg-light-green-2;
                }

                .students {
                    color: $green;
                }

                .orange {
                    color: $orange;
                }

                .purple {
                    color: $purple-2;
                }
            }
        }

        .stat-frame.first {
            margin-left: 0px;
        }
    }
}