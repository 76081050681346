@import '../../../../globals/Colors.scss';

.summary-student-view, .summary-district-view, .summary-school-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    // max-width: 1110px;
    max-width: 1300px;
    margin-bottom: 60px;

    .times-frame {
        padding: 0px;
        display: flex;
        margin: 25px 0px;

        .checkbox-view:nth-child(even) {
            margin: 0px 30px;
        }
    }

    .table-frame {
        width: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid $grey-2;

        .table-toolbar-frame {
            width: 100%;
            display: flex;
            padding: 16px;
            box-sizing: border-box;
            justify-content: space-between;
            border-bottom: 1px solid $grey-2;
    
            .search-field-view {
                flex: 0 0 40%;
            }

            .table-toolbar-button {
                border: none;
                color: white;
                cursor: pointer;
                border-radius: 4px;
                padding: 6px 12px;
                font-size: 12px;
                background: $blue;
                box-sizing: border-box;
                text-transform: uppercase;
            }

            a {
                font-family: 'Inter';
                font-weight: bold;
                display: flex;
                align-items: center;
                text-decoration: none;
            }

            .clear-all-filters-btn {
                border: none;
                background-color: #D8DFEC;
                cursor: pointer;
                color: #4E5D78;
                opacity: 0.9;
                border-radius: 3px;
                padding: 0 15px;
            }
        }
        
        .table-view {
            border: 0px;
            border-bottom: 1px solid $grey-2;
            border-radius: 0px;            
        }

        .tooltip-view {
            height: 50px;
            align-self: center;
        }
    }
}