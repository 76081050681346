.navigation-slider-view {
    width: 125px;
    transition: 0.3s;

    .navigation-button {
        display: flex;

        .nav-img {
            display: none;
        }
        
        .nav-label {
            font-size: 14px;
            font-weight: bold;
        }
    }
}

.navigation-slider-view.expand {
    width: 400px;
}