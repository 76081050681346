@import '../../globals/Colors.scss';

.checkbox-view {
    display: flex;
    cursor: pointer;
    
    .checkbox-icon {
        display: flex;
        align-self: center;
    }

    .right-section {
        display: flex;
        margin-left: 10px;
        flex-direction: column;
        justify-content: center;

        .title {
            color: $navy;
            font-size: 16px;
            font-weight: bold;
        }

        .subtitle {
            color: $grey-3;
            font-size: 10px;
        }
    }
}