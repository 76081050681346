@import '../../../../globals/Colors.scss';

.summary-plots-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    max-width: 1110px;
    margin-bottom: 60px;

    .times-frame {
        padding: 0px;
        display: flex;
        margin: 25px 0px;

        .checkbox-view:nth-child(even) {
            margin: 0px 30px;
        }
    }

    .graphs-frame {
        width: 100%;
        height: 562px;
        min-height: 562px;

        .empty-graph-frame {
            display: flex;
            align-items: center;
            justify-content: center;

            .hint-label {
                font-size: 18px;
                margin: 30px 0px 50px 0px;
            }
        }

        .section-frame {
            .section-header {
                font-size: 14px;
                font-weight: bold;
                padding: 10px;
                color: $navy;
                text-align: center;
                background: #ECF2FE;
            }

            .subsection-frame {
                width: 100%;
                height: 480px;
                display: flex;

                div {
                    flex: 1 1 0%;
                    min-width: 0px;
                }
            }

            .hint-label {
                text-align: center;
                margin: 60px auto;
                padding-bottom: 60px;
            }
        }
    }

    .tooltip-view {
        margin-bottom: 50px;
        justify-content: center;
    }

    .subgraphs-frame {
        display: flex;
        flex-wrap: wrap;
        padding: 50px;

        .subgraph-frame {
            width: 50%;

            .subgraph-header-frame {
                display: flex;

                .grade-frame, .grade-level {
                    display: flex;
                    margin: 0px 20px 15px 12.5px;
                    flex-direction: column;

                    .grade-header {
                        font-size: 10px;
                        color: $navy;
                    }

                    .grade {
                        color: $navy;
                        font-size: 21px;
                        font-weight: bold;
                    }
                }
            }

            .graph {
                width: 100%;
                height: 450px;
            }

            .empty-label {
                color: $grey;
                font-style: italic;
            }
        }
    }
}