@import '../../../../globals/Colors.scss';

.user-responses-container {
  color: #4E5D78;
  width: 100%;

  .user-response-table-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #C0C0C0;

    p {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .hint-label {
    font-size: 18px;
    color: $grey;
    margin-top: 120px;
    font-style: italic;
    display: flex;
    justify-content: center;
  }
}
