@import '../../globals/Colors.scss';

$border-radius: 5px;

.select-menu-view {
    position: relative;
    height: 56px;
    background: transparent;
    border: 2px solid $blue;
    border-radius: $border-radius;

    .menu-header {
        position: absolute;
        z-index: 1;
        top: -8px;
        left: 10px;
        font-size: 10px;
        padding: 0px 5px;
        color: $blue;
        background: linear-gradient(0deg, transparent 30%, $bg-light-blue 0%);
    }

    .select-search-container {
        height: 100%;
        width: 100% !important;

        .select-search-value {
            height: 100%;

            input {
                height: 100%;
                border: none;
                color: black;
                font-size: 16px;
                padding: 0px 30px 0px 10px;
                background: transparent;
            }
        }

        .select-search-select {
            max-height: 200px;
            border: none !important;
            box-shadow: 0 .0625rem .625rem rgba(0, 0, 0, 0.15);

            .select-search-options {
                margin: 0px;
                padding: 0px;
                list-style: none;

                .select-search-option, .select-search-not-found {
                    height: auto;
                    padding: 10px;
                    display: flex;
                    color: black;
                    align-items: center;
                    box-sizing: border-box;
                    background: white !important;
                    text-wrap: wrap;
                    
                }

                .select-search-option:hover {
                    background: $bg-light-blue !important;
                }
            }
        }

        .select-search-select::-webkit-scrollbar, .select-search-options::-webkit-scrollbar {
            // display: none;
        }
    }

    .select-search-is-selected {
        color: $blue !important;
    }

    .arrow-icon {
        position: absolute;
        top: 0px;
        right: 5px;
        bottom: 0px;
        display: flex;
        align-items: center;
        

        img {
            height: 24px;
            transition-duration: 0.3s;
            transition-property: transform;
        }
    }

    .arrow-icon.down {
        img {
            transform: rotate(-180deg);
        }
    }
}

.select-menu-view.disabled {
    cursor: not-allowed;
    border-color: $hint;

    .menu-header {
        color: $hint;
    }

    .select-menu {
        color: $hint;
        cursor: not-allowed;
    }
}