.admin-view {
  display: flex;
  min-width: 750px;
  flex-direction: column;
  padding: 35px;

  table {
    position: relative;
  }
  th {
    color:#D5DDE5;;
    background:#1b1e24;
    border-bottom:4px solid #9ea7af;
    border-right: 1px solid #343a45;
    font-size:18px;
    font-weight: 600;
    padding:8px;
    text-align:left;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    vertical-align:middle;
    position: sticky;
    top: 0;
  }
  th:first-child {
    border-top-left-radius:3px;
  }
  th:last-child {
    border-top-right-radius:3px;
    border-right:none;
  }
  tr {
    border-top: 1px solid #C1C3D1;
    border-bottom: 1px solid #C1C3D1;
    color:#666B85;
    font-size:16px;
    font-weight:normal;
    text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
  }
  tr:first-child {
    border-top:none;
  }
  tr:last-child {
    border-bottom:none;
  }
  tr:nth-child(odd) td {
    background:#EBEBEB;
  }
  tr:last-child td:first-child {
    border-bottom-left-radius:3px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius:3px;
  }
  td {
    background:#FFFFFF;
    padding:10px;
    text-align:left;
    vertical-align:middle;
    font-weight:400;
    font-size:14px;
    border-right: 1px solid #C1C3D1;
  }
  td:last-child {
    border-right: 0px;
  }
  th.text-left {
    text-align: left;
  }
  th.text-center {
    text-align: center;
  }
  th.text-right {
    text-align: right;
  }
  td.text-left {
    text-align: left;
  }
  td.text-center {
    text-align: center;
  }
  td.text-right {
    text-align: right;
  }
}