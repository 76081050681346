@font-face {
    font-family: 'Inter';
    src: url('./fonts/Inter/static/Inter-Regular.ttf')
}

@font-face {
    font-family: 'Inter';
    font-weight: bold;
    src: url('./fonts/Inter/static/Inter-Bold.ttf')
}

@font-face {
    font-family: 'Inter';
    font-weight: lighter;
    src: url('./fonts/Inter/static/Inter-Light.ttf')
}

body {
    font-family: 'Inter';
    margin: 0;
}

.full-screen-view {
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    min-height: 250px;
}

.centered-frame {
    width: 90%;
    margin: 0 auto;
}

.ellipsis {
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.scroll {
    overflow-y: scroll;
}

.scroll::-webkit-scrollbar {
    display: none;
}