@import '../../globals/Colors.scss';

.tabs-view {
    display: flex;
    flex-direction: column;

    .tabs-list-view {
        // position: sticky;
        // top: 0px;
        margin: 0px;
        padding: 0px;
        display: flex;
        list-style: none;
        background: white;
        justify-content: center;

        .tab-cell {
            color: $grey;
            height: 40px;
            display: flex;
            cursor: pointer;
            font-size: 12px;
            padding: 0px 10px;
            margin-left: 30px;
            font-weight: bold;
            align-items: center;
            text-transform: uppercase;
            border-bottom: 2px solid transparent;
        }

        .tab-cell:first-child {
            margin-left: 0px;
        }

        .tab-cell.selected {
            color: $blue;
            border-color: $blue;
        }
    }

    .tab-content-frame {
        position: relative;
        flex: 1 1;
    }
}