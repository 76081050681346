@import '../../globals/Colors.scss';

.dashboard-view {
    display: flex;
    min-width: 750px;
    flex-direction: column;

    .logo {
        display: flex;
        flex: 0 0 85px;
        align-items: center;
        justify-content: center;
    }

    .content-frame {
        flex: 1;
        display: flex;
        flex-direction: column;

        .top-section {
            // position: sticky;
            top: 0px;
            z-index: 10;
            padding: 35px;
            background: $bg-light-blue;

            .expand-button {
                position: absolute;
                top: 0px;
                right: 0px;
                padding: 10px;
                color: $blue;
                border: none;
                cursor: pointer;
                background: none;
                font-size: 12px;
                font-weight: bold;
            }

            .centered-frame {
                padding-top: 5px;
                transition: max-height 0.3s ease-in;
                max-height: 600px;
                max-width: 1100px;
            }

            .centered-frame.collapse {
                overflow: hidden;
                max-height: 60px;
                transition: max-height 0.3s ease-out;
            }

            .selection-menus-frame {
                flex: 1 1;
                
                display: flex;
                justify-content: space-between;

                .selection-menu {
                    margin: 0 0.25%;
                    flex-basis: 22%;
                }
            }

            .clear-button {
                border: none;
                color: $navy;
                outline: none;
                cursor: pointer;
                font-size: 16px;
                font-weight: bold;
                background: none;
                padding: 0px 10px;
            }

            .filter-report-stats-view {
                margin-top: 20px;
            }
        }

        .tabs-view {
            flex: 1 1;

            .tabs-list-view {
                margin-top: 24px;
            }

            // .tab-content-frame {
            //     .summary-student-view {
            //         .table-headers {
            //             position: sticky;
            //         }
            //     }
            // }
        }

        .hint-label {
            font-size: 18px;
            color: $grey;
            // font-weight: bold;
            margin-top: 120px;
            font-style: italic;
            align-self: center;
        }
    }
}