@import '../../globals/Colors.scss';

.table-view {
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    border: 1px solid $grey-2;

    .table-headers {
        // position: sticky;
        // top: 0px;
        display: flex;
        margin: 0px;
        padding: 0px;
        height: 80px;
        list-style: none;
        background: white;
        align-items: center;
        border-bottom: 1px solid $grey-2;

        .table-header {
            font-size: 12px;
            font-weight: bold;
            line-height: 200%;
            text-align: center;
            // padding: 0 1%;
            box-sizing: border-box;

            button {
                width: 100%;
                padding: 2px 16px;
                // padding: 2px;
                font-size: 11px;
                text-align: center;
                box-sizing: border-box;
                align-self: center;
                min-height: 40px;
                border-radius: 4px;
                background-color: white;;
                border: none;
                color: #4E5D78;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 500;

                &:hover {
                    background-color: #ECF2FE;
                }

                .cell-label {
                    display: flex;
                    align-items: center;

                    .sort-col-label {
                        margin: 0 5px 0 0;
                    }

                    .col-filter-count {
                        color: #349CFF;
                    }

                    .sort-icon {
                        margin-left: 3px;
                        padding: 7px 2px;
                    }

                    .sort-icon:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .hint-cell {
        height: 52px;
        display: flex;
        font-size: 12px;
        font-style: italic;
        align-items: center;
        justify-content: center;
    }

    .data-list-view {
        margin: 0px;
        padding: 0px;
        list-style: none;

        .table-cell {
            min-height: 52px;
            display: flex;
            align-items: stretch;
            border-bottom: 1px solid $grey-2;

            .cell-label, .scored-cell {
                padding: 0 1%;
                font-size: 12px;
                text-align: center;
                box-sizing: border-box;
            }

            .cell-label {
                align-self: center;
            }

            .scored-cell {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: center;
            }
        }

        .table-cell.clickable {
            cursor: pointer;
        }

        .table-cell:last-child {
            // border: none;
        }
    }
}