@import '../../globals/Colors.scss';

$border-radius: 5px;

.search-field-view {
    position: relative;
    height: 40px;
    background: transparent;
    border: 1px solid $grey-2;
    border-radius: $border-radius;

    .menu-header {
        position: absolute;
        z-index: 1;
        top: -7px;
        left: 10px;
        font-size: 10px;
        padding: 0px 5px;
        color: $grey;
        background: linear-gradient(0deg, white 30%, white 0%);
    }
    
    .search-input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        font-size: 12px;
        background: none;
        padding: 0px 10px;
        box-sizing: border-box;
    }
}
