@import '../../globals/Colors.scss';

.individual-page-button {
  padding: 5px 7px;
  cursor: pointer;
  border: none;
  color: black;
  font-size: 12px;
}

.pager-buttons-container {
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .lighter {
    color: #808080;
  }

  .pager-select {
    margin: 0 5px;
    border: none;
  }

  .pager-navigate-chevron {
    padding: 5px;
    cursor: pointer;
    border: none;
    color: #333333;
    background-color: white;
  }

  .pager-font {
    font-size: 12px;
    margin-left: 5px;
  }
}