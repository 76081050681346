@import '../../globals/Colors.scss';

.scored-category {
  border: 5px solid;
}

.scored-category.light-orange {
  border-color: $bg-light-orange
}

.scored-category.orange {
  border-color: $bg-orange
}

.scored-category.light-green {
  border-color: $bg-light-green
}

.scored-category.light-green-2 {
  border-color: $bg-light-green-2
}

.scored-category.light-grey {
  border-color: $light-grey
}