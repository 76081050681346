@import '../../globals/Colors.scss';

.scored-cell {

}

.scored-cell.light-orange {
    background: $bg-light-orange
}

.scored-cell.orange {
    background: $bg-orange
}

.scored-cell.light-green {
    background: $bg-light-green
}

.scored-cell.light-green-2 {
    background: $bg-light-green-2
}

.scored-cell.light-grey {
    background: $light-grey
}